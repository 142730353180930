import React, { useEffect } from 'react'
import styled from 'styled-components'
import useProductContext from 'hooks/useProductContext'
import Image from 'components/Image'
import Carousel from 'components/Carousel'
import { mediaQuery } from 'utils/style'

const Property = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0 32px;
  margin: 0 8px;
  overflow: hidden;
  ${mediaQuery()}
`

const Title = styled.div`
  font-size: 18px;
`

export default function ProductPropertyCarousel ({
  product,
  property,
  dimensions
}) {
  const { selected, select } = useProductContext({ id: product }, property?.id)
  useEffect(
    () =>
      property?.component?.properties?.[0]?.id
      && !selected
      && select(property?.component?.properties?.[0]?.id),
    [property]
  )
  return (
    <Property>
      <Title>{property?.component?.group?.[0]?.component?.title}</Title>
      <Carousel
        style={{ overflowX: 'visbile', overflowY: 'hidden' }}
        init={Math.max(
          property?.component?.properties?.findIndex(
            prop => prop.id === selected
          ),
          0
        )}
        width="100%"
        height="100%"
        squared={1}
        transform={idx =>
          `translateX(${(25 * idx)
            / (Math.abs(idx) * 0.1 + 1)}%) translateY(${(5 * Math.abs(idx))
            / (Math.abs(idx) * 0.2 + 1)}%) scale(${1
            / (Math.abs(idx) * 0.3 + 1)})`
        }
        navigation
        infinite
        onSlide={idx => select(property?.component?.properties[idx]?.id)}
      >
        {property?.component?.properties?.map(prop => (
          <Image
            key={prop.id}
            src={prop.component?.image}
            alt={prop.component?.imageAlt}
            title={prop.component?.imageTitle}
            description={prop.component?.imageDescription}
            style={{ filter: 'drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5))' }}
          />
        ))}
      </Carousel>
      {/* <div>
        {property?.component?.properties?.find(prop => prop.id === selected)
          ?.component?.imageTitle
          || property?.component?.properties?.[0]?.component?.imageTitle}
      </div> */}
    </Property>
  )
}
